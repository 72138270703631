<template>
  <div>
    <div class="container">
      <div class="notice-box">
        <div class="notice-title">
          个人信息
        </div>

        <!-- 个人信息表单 -->
        <div class="info-box">
          <el-row :gutter="10">
            <el-col :span="5">
              <div class="info-img">
                <my-upload
                  v-model="show"
                  :width="500"
                  :height="700"
                  :url="picUrl(username)"
                  method="put"
                  :params="params"
                  :headers="headers"
                  @crop-success="cropSuccess"
                  @crop-upload-success="cropUploadSuccess"
                  @crop-upload-fail="cropUploadFail"
                />
                <img class="img" :src="avatarSrc" alt="avatar">
                <div class="img-add-btn" @click="toggleShow">修改照片</div>
              </div>
            </el-col>
            <!-- 专家基本信息表单 -->
            <el-col :span="19">
              <div>
                <el-form ref="form" :rules="rules" :model="form" label-width="120px">
                  <el-row>
                    <el-col :span="12">
                      <el-form-item label="评委姓名" prop="realName">
                        <el-input v-model="form.realName" class="input-form" />
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="性别">
                        <el-select v-model="form.gender" class="input-form" placeholder="请选择用户性别">
                          <el-option label="未知" value="未知" />
                          <el-option label="男" value="男" />
                          <el-option label="女" value="女" />
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <!-- 待输入建议的输入框 -->
                      <el-form-item label="工作单位" prop="university">
                        <!-- <el-input class="input-form" v-model="form.university" /> -->
                        <el-autocomplete
                          v-model="form.university"
                          class="inline-input input-form"
                          :fetch-suggestions="querySearch"
                          placeholder="请输入内容"
                          @select="handleSelect"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="职称">
                        <el-select v-model="form.type" class="input-form" placeholder="请选择用户职称">
                          <el-option label="教授" value="教授" />
                          <el-option label="副教授" value="副教授" />
                          <el-option label="讲师" value="讲师" />
                          <el-option label="助教" value="助教" />
                          <el-option label="研究员" value="研究员" />
                          <el-option label="副研究员" value="副研究员" />
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <el-form-item label="身份证号" prop="identityNumber">
                        <el-input v-model="form.identityNumber" class="input-form" />
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="电话" prop="phone">
                        <el-input v-model="form.phone" class="input-form" />
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-form-item label="通讯地址">
                    <el-input v-model="form.address" class="input-full-form" />
                  </el-form-item>
                  <!--          <el-form-item label="评审历史">-->
                  <!--            <span v-for="(item, id) in judge_history" :key="id" class="judge-history">{{ item }}</span>-->
                  <!--          </el-form-item>-->

                  <el-form-item class="btn-place">
                    <el-button class="btn" type="primary" @click="onEdit('form')">保存修改</el-button>
                    <!-- <el-button class="btn" type="primary" @click="onDelete">删除</el-button> -->
                  </el-form-item>
                </el-form>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserInfo, newUserProfile } from '@/api/usermanagement.js'
import { getUniversityJson } from '@/utils/universities.js'
import 'babel-polyfill'
import myUpload from 'vue-image-crop-upload'
import { userApi } from '@/api/usermanagement'

const { getAuthorizationHeaderObj } = require('@/utils/auth')

export default {
  components: { myUpload },
  data() {
    return {
      username: null,
      picBaseUrl: null,
      // 添加照片
      show: false,
      params: {},
      headers: getAuthorizationHeaderObj(),
      param: null, // 图片的 blob 格式
      imgDataUrl: '', // the database64 url of created image
      picUrl: uname => userApi.uploadAvatarURL(this.username),

      loading: true, // 用来同步后台接口异步调用的时间差
      avatarSrc: '',
      form: null, // 专家基本信息表单
      judge_history: [
        '2020年第一次',
        '2020年第二次',
        '2020年第三次'
      ],

      // 所有学校的数据
      allUniversities: null,

      // 表单验证规则
      rules: {
        realName: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        university: [
          { required: true, message: '请输入单位名称', trigger: 'blur' }
        ],
        phone: [
          { pattern: /^[0-9]*$/, required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        identityNumber: [
          { pattern: /(^\d{15}$)|(^\d{17}([0-9]|X)$)/, message: '请输入正确的身份证号', trigger: 'blur' }
        ]
      }
    }
  },
  mounted() {
  },
  created() {
    this.username = this.globalGetUsername()
    this.picBaseUrl = process.env.VUE_APP_PIC_URL
    console.log(this.picBaseUrl)
    // console.log('uuuuser name')
    // console.log(this.username)
    this.loading = true
    this.fetchUserInfo()
    this.allUniversities = getUniversityJson() // 获取学校数据
  },
  methods: {
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(',')
      var mime = arr[0].match(/:(.*?);/)[1]
      var bstr = atob(arr[1])
      var n = bstr.length
      var u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    // 上传照片
    // 裁剪成功后的回调函数
    cropSuccess(imgDataUrl, field) {
      console.log('-------- crop success --------')
      this.imgDataUrl = imgDataUrl // 用于存储剪切完图片的base64Data和显示回调图片
      this.param = new FormData().append('avatar', this.dataURLtoFile(imgDataUrl, 'avatar'))
    },
    // 服务器返回数据，进行 json 转码
    cropUploadSuccess(jsonData, field) {
      console.log('-------- upload success --------')
      const _this = this
      setTimeout(function() {
        _this.avatarSrc = process.env.VUE_APP_PIC_URL + jsonData.data
      }, 800)
    },
    // 获取服务器的api, 如500
    cropUploadFail(status, field) {
      console.log('-------- upload fail --------')
      console.log(status)
    },
    toggleShow() {
      this.show = !this.show
    },
    // 根据用户名获取用户信息
    fetchUserInfo() {
      getUserInfo(this.username).then(response => {
        this.form = response.data
        this.avatarSrc = userApi.previewAvatar(this.form.avatarSrc)
        this.loading = false
        // console.log(this.form)
        // console.log(this.loading)
        // console.log(response)
      })
    },
    // 修改用户个人信息
    onEdit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          newUserProfile(this.username, this.form).then(response => {
            console.log(this.form)
            console.log('edit expert info')
            this.$message({
              type: 'success',
              message: '修改成功!'
            })
          }).catch(() => {
            this.$message({
              type: 'waring',
              message: '修改失败'
            })
          })
        } else {
          this.$message({
            type: 'waring',
            message: '修改失败'
          })
        }
      })
    },
    onDelete() {
      this.$confirm('此操作将删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 确定，进行用户删除
        this.form.isLocked = true
        // console.log(this.form)
        newUserProfile(this.username, this.form).then(response => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.$router.push({ name: 'ExpertList' })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    querySearch(queryString, cb) {
      var allUniversities = this.allUniversities
      var results = queryString ? allUniversities.filter(this.createFilter(queryString)) : allUniversities

      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter(queryString) {
      return (universities) => {
        return (universities.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
    },
    handleSelect(item) {
      console.log(item)
    }
  }
}
</script>

<style scoped>
@import '~@/styles/theme.css';

.border {
  border: 1px solid red;
}

.container {
  width: 100%;
  min-height: calc(100vh - 60px);
  background-color: var(--background-light-color);
  /* border: 1px solid red; */
  text-align: center;
}

.notice-box {
  display: inline-block;
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  margin-top: 40px;
  height: 610px;
  width: 1050px;
  /* border: 1px solid red; */
}

.notice-title {
  text-align: center;
  margin-top: 10px;
  height: 80px;
  /* border: 1px solid red; */
  font-size: 20px;
  line-height: 80px;
}

.info-box {
  width: 80%;
  margin: auto;
  margin-top: 30px;
  /* height: 200px; */
}

.info-img {
  position: relative;
}

.img {
  width: 100%;
}

.img-add-btn {
  display: none;
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 25px;
  /* border-radius: 2px; */
  background-color: rgba(0,0,0,.8);
  text-align: center;
  font-size: 13px;
  line-height: 25px;
  color: cornsilk;
}

.info-img:hover .img-add-btn {
  display: block;
}

.img-add-btn:hover {
  cursor: pointer;
}

.input-form {
  width: 100%;
}
.input-full-form {
  width: 100%;
}

.btn-place {
  margin-top: 130px;
  text-align: center;
}

.btn {
  width: 120px;
  margin-right: 250px;
}
</style>
